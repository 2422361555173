import React from 'react'
import FacebookCallback from '../../components/callback/FacebookCallback';
import Layout from '../../templates/layout';

const FacebookPage = (props) => {
  return (
    <Layout>
      <FacebookCallback location={props.location}/>
    </Layout>
  )
}

export default FacebookPage
