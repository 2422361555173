import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Alert, AlertTitle } from '@mui/material';
import BasePageContent from '../utils/BasePageContent';
import { navigate } from 'gatsby';

const REDIRECT_TO = {
	LOGGED_IN: '/payment',
	LINKED: '/user/identity'
};

function mapStateToProps(state) {
	return {
		facebook: state.facebookCallback,
	};
}

class FacebookCallback extends Component {
	constructor(props){
		super(props);
		this.state = {
			showErrors: false,
      showSuccess: false,
      loading: true,
		}
	}

  componentDidMount(){
		let params = this.props.location.search;
    this.props.actions.facebookCallback(params);
	}

	componentDidUpdate(prevProps){
		if(prevProps.facebook !== this.props.facebook){
			let fb = this.props.facebook;
			if(fb.access_token){
				let token = "Bearer " + fb.access_token;
				localStorage.setItem('authToken', token);
				localStorage.setItem('refreshToken', fb.refresh_token);
        navigate(REDIRECT_TO.LOGGED_IN);
			} else if(fb.data){
				if(fb.data.access_token){
					let token = "Bearer " + fb.data.access_token;
					localStorage.setItem('authToken', token);
					localStorage.setItem('refreshToken', fb.data.refresh_token);
          navigate(REDIRECT_TO.LOGGED_IN);
				} else if(fb.data.twoFactorAuth){
					navigate(REDIRECT_TO.LINKED)
				} else if(fb.data.email){
					this.setState({showSuccess: true, loading: false})
				}
			} else if(fb.reason) {
				this.setState({message: fb.reason, showErrors: true, loading: false})
			} else {
				this.setState({message: 'Błąd - coś poszło nie tak', showErrors: true, loading: false})
			}
		}
	}

	successMessage() {
    return (
      <Alert severity='success'>
        <AlertTitle>Rejestracja przebiegła pomyślnie</AlertTitle>
        Wejdź w swoją skrytkę pocztową i zweryfikuj swój adres email, aby móc się zalogować
      </Alert>
    )
  }


	render() {
    return (
      <BasePageContent loading={this.state.loading}>
        {this.state.showErrors && <Alert severity='error'>
          <AlertTitle>{this.state.message}</AlertTitle>
        </Alert>}
        {this.state.showSuccess && this.successMessage()}
      </BasePageContent>
    )
  }
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookCallback)
